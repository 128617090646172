import React from "react";
import { FcGoogle } from "react-icons/fc";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";

function Login() {
  const SignIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log(result);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);
        const userRef = doc(db, "users", user?.uid);
        await setDoc(
          userRef,
          {
            displayName: user?.displayName,
            email: user?.email,
            uid: user?.uid,
            photoUrl: user?.photoURL,
          },
          { merge: true }
        );
        console.log("saved");
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);

        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <div className="flex w-full h-screen items-center justify-center bg-lightBlack">
      <div className="flex flex-col text-white justify-center text-center">
        <h1 className="text-5xl">Phineas</h1>
        <p className="mb-32 mt-10 text-2xl tracking-wider">
          A specialized teaching assistant for ESL educators.
        </p>

        <p className="text-xl">Closed Beta</p>

        <div
          onClick={SignIn}
          className="cursor-pointer p-3 w-[300px] self-center mt-4 bg-white rounded-3xl flex gap-2 text-lightBlack items-center justify-center"
        >
          <FcGoogle className="text-xl" />
          <p>Sign in with Google</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
