import React, { useEffect, useState } from "react";
import Btn from "../../components/btn";
import {
  collection,
  query,
  doc,
  setDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import useUser from "../../hooks/useUser";

function Admin() {
  const [users, setUsers] = useState([]);
  const { user: currentUser } = useUser();

  const deleteUser = async (userId) => {
    try {
      await deleteDoc(doc(db, "users", userId));
    } catch (error) {
      console.log(error);
    }
  };

  const makeAdmin = (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      setDoc(userRef, { admin: true }, { merge: true });
    } catch (error) {
      console.log(error);
    }
  };

  const UsersComponent = () => (
    <div className="w-full min-h-[300px] bg-white rounded-lg shadow-lg p-2">
      <div className="flex flex-row justify-between items-center w-full ">
        <h1 className="text-lg font-bold">Users</h1>
        {/* <Btn
          title={"Add User"}
          onClick={(e) => {
            e.stopPropagation();
            console.log("Add new User");
          }}
        /> */}
      </div>
      <div className="flex flex-col gap-3 w-full mt-7">
        {users
          .filter((user) => !user?.admin)
          .map((user) => (
            <div className="flex flex-row justify-between items-center w-full mt-2">
              <div className="flex flex-1">
                <div className="flex  gap-2 items-center p-1 border-[0.5px] border-gray-500 rounded-full relative">
                  <img
                    src={user.photoUrl}
                    alt="profile"
                    className="w-6 h-6 rounded-full object-cover"
                  />
                  <h1 className="text-sm text-gray-400 font-light">
                    {user?.email}
                  </h1>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between flex-1">
                <div
                  onClick={() => {
                    deleteUser();
                  }}
                  className="cursor-pointer border-[1px] p-1 px-4 flex items-center justify-center font-light text-secondary border-secondary rounded-full"
                >
                  Delete
                </div>
                <div
                  onClick={() => {
                    makeAdmin(user?.id);
                  }}
                  className="cursor-pointer border-[1px] p-1 px-4 flex items-center justify-center font-light text-secondary border-secondary rounded-full"
                >
                  Make Admin
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  const AdminComponent = () => (
    <div className="w-full min-h-[300px] bg-white rounded-lg shadow-lg p-2">
      <div className="flex flex-row justify-between items-center w-full ">
        <h1 className="text-lg font-bold">Admins</h1>
      </div>
      <div className="flex flex-col gap-3 w-full mt-7">
        {users
          .filter((user) => user?.admin)
          .map((user) => (
            <div className="flex flex-row justify-between items-center w-full mt-2">
              <div className="flex flex-1">
                <div className="flex  gap-2 items-center p-1 border-[0.5px] border-gray-500 rounded-full relative">
                  <img
                    src={user.photoUrl}
                    alt="profile"
                    className="w-6 h-6 rounded-full object-cover"
                  />
                  <h1 className="text-sm text-gray-400 font-light">
                    {user?.email}
                  </h1>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  useEffect(() => {
    const q = query(collection(db, "users"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ id: doc?.id, ...doc.data() });
      });
      console.log("users: ", users);
      setUsers(users);
    });
    return unsubscribe;
  }, []);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 m-2">
      <UsersComponent />

      <AdminComponent />
    </div>
  );
}

export default Admin;
