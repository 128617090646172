import React, { useEffect, useRef, useState } from "react";
import useFileUpload from "react-use-file-upload";
import { storage, db as firestore, storageRef } from "../../firebase";
import { uploadBytes, getDownloadURL } from "firebase/storage";
import { BsFileEarmarkText } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import axios from "axios";

function UploadTrainings() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const uploadFile = (e) =>
    new Promise(async (resolve, reject) => {
      const refName = `${uuidv4()}`;

      try {
        const pdfRef = storageRef(storage, `files/${refName}`);

        await uploadBytes(pdfRef, e);
        console.log("Uploaded a blob or file!");

        const downloadUrl = await getDownloadURL(pdfRef);

        const docRef = await addDoc(collection(firestore, "files"), {
          url: downloadUrl,
          storageRef: refName,
        });
        console.log("Document written with ID: ", docRef);

        ///Train Data API
        const res = await axios.post(`${process.env.REACT_APP_AI_URL}/update`, {
          url: downloadUrl
        })

        toast.success(res.data);
        resolve(docRef);
      } catch (error) {
        reject(error);
      }
    });

  const handleSubmit = async (pdfsToUpload) => {
    try {
      setLoading(true);
      Promise.all(pdfsToUpload.map(async (e) => uploadFile(e))).then(
        (values) => {
          clearAllFiles();

          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // setPdfs(files);
    if (files.length > 0) {
      handleSubmit(files);
    }
  }, [files]);

  return (
    <div className="flex flex-col w-full">
      <div
        className="bg-skyBlue bg-opacity-25 m-2 md:m-8 rounded-md border-[2px] cursor-pointer flex  py-2 h-[250px] flex-col items-center justify-center border-dashed border-skyBlue "
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={(e) => {
          console.log(e);
          handleDragDropEvent(e);
          console.log(files);
          // handleSubmit(e)
          setFiles(e, "a");
        }}
        // onClick={() => inputRef.current.click()}
      >
        {!loading ? (
          <>
            <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
              <BsFileEarmarkText className="text-skyBlue text-xl" />
            </div>
            <p className="font-semibold text-gray-800 my-3">
              Drag and drop file here
            </p>

            <p className="font-light text-gray-400 my-1 text-sm">
              Support zip and rar files
            </p>

            <div
              onClick={() => {
                inputRef.current.click();
              }}
              className="py-2 px-5 mt-1 bg-skyBlue rounded-xl text-white"
            >
              Browse File
            </div>

            <input
              ref={inputRef}
              type="file"
              multiple={false}
              style={{ display: "none" }}
              onChange={(e) => {
                console.log(e);

                setFiles(e, "a");
                inputRef.current.value = null;
              }}
            />
          </>
        ) : (
          <Oval
            height={55}
            width={55}
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fff"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        )}
      </div>
    </div>
  );
}

export default UploadTrainings;
