import { create } from "zustand";

const useOriginalTextModal = create((set) => ({
    text: "",
    isOpen: false,
    setText: (value) => set((state) => ({ text: value })),
    onOpen: () => set((state) => ({ isOpen: true })),
    onClose: () => set((state) => ({ isOpen: false })),
}));

export default useOriginalTextModal