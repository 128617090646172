import React from "react";
import useHistory from "../../hooks/useHistory";
import Btn from "../../components/btn";
import moment from "moment";
import useOriginalTextModal from "../../hooks/useOriginalTextModal";

function History() {
  const { history } = useHistory();
  const { onOpen, setText } = useOriginalTextModal();

  const HistoryComp = ({ item }) => {
    console.log(item);
    return (
      <div className="border-t-[0.5px] border-t-gray-400 p-2 py-4 flex flex-col">
        <div className="flex w-full items-center justify-between">
          <Btn title={item?.type} />
          <div className="flex items-center gap-3">
            <div className="text-sm text-gray-400">
              {moment(new Date(item?.timeStamp?.toDate())).format(
                "MMM Do | h:mm a"
              )}
            </div>

            <div
              onClick={() => {
                setText(item?.originalText);
                onOpen();
              }}
              className="cursor-pointer border-[1px] p-1 px-4 flex items-center justify-center font-light text-secondary border-secondary rounded-full"
            >
              Original Text
            </div>
          </div>
        </div>

        <div className="p-2">{item?.newText}</div>
      </div>
    );
  };

  return (
    <>
      <div className=" flex flex-col bg-white rounded-xl m-4">
        <h1 className="font-semibold m-4">History</h1>

        {history.map((item) => (
          <HistoryComp item={item} />
        ))}
      </div>
      <div className="h-10" />
    </>
  );
}

export default History;
