import { Route, Routes, useNavigate, useNavigation } from "react-router-dom";

import Home from "../screens/app/home";
import History from "../screens/app/history";
import Saved from "../screens/app/saved";
import UploadTrainings from "../screens/app/uploadTrainings";
import Admin from "../screens/app/admin";
import useUser from "../hooks/useUser";

const NotFound = () => {
  return (
    <div className="flex h-full w-full  items-center justify-center text-3xl font-bold">
      Not Found!
    </div>
  );
};

export function AppNavigation() {
  const { user } = useUser();
  return (
    <div className="h-full">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/history" element={<History />} />
        <Route path="/saved" element={<Saved />} />
        {user && <Route path="/training" element={<UploadTrainings />} />}
        {user && <Route path="/admin" element={<Admin />} />}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
