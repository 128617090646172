import React, { useState } from "react";

import Modal from "./modal";
import useOriginalTextModal from "../../hooks/useOriginalTextModal";

function OriginalTextModal() {
  const originalTextModal = useOriginalTextModal();

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <p>{originalTextModal?.text}</p>
    </div>
  );

  return (
    <Modal
      isOpen={originalTextModal.isOpen}
      onClose={originalTextModal.onClose}
      onSubmit={() => {}}
      title={"Original Text"}
      actionLabel="Continue"
      disabled={false}
      body={bodyContent}
      // footer={footerContent}
    />
  );
}

export default OriginalTextModal;
