import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { HiOutlineLogout, HiOutlineDocumentText } from "react-icons/hi";
import { PiSquaresFourFill } from "react-icons/pi";
import { BsBookmarkDash } from "react-icons/bs";
import { HiOutlineUpload } from "react-icons/hi";
import { GoClock } from "react-icons/go";
import { MdOutlineShowChart } from "react-icons/md";
// import logo from '../assets/logos/image.png'
// import useUser from "../hooks/useUser";
import useSidebar from "../hooks/useSidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Navbar from "./Navbar";
import useUser from "../hooks/useUser";

function Sidebar({ children }) {
  const { pathname } = useLocation();
  const { user } = useUser();
  const navigate = useNavigate();

  //   const { user, setUser } = useUser();
  // const [isOpen, setIsOpen] = useState(true)
  // const [menuHidden, setMenuHidden] = useState(false)
  const { isOpen, setIsOpen, menuHidden, setMenuHidden } = useSidebar();

  return (
    <div className="flex h-full w-full bg-white fixed">
      <div
        className={`${isOpen ? "w-72" : "w-20"} ${
          menuHidden
            ? `-translate-x-full  overflow-hidden p-0 scale-x-0  ${
                isOpen ? "-mr-72" : "-mr-20"
              }`
            : "translate-x-0"
        }    duration-300 h-screen bg-white flex flex-col justify-between relative px-4 pt-8`}
      >
        {/* <div
          onClick={() => setIsOpen(!isOpen)}
          className={`${
            !isOpen && "rotate-180"
          } duration-300 absolute cursor-pointer -right-[11px] top-9 w-6 border-2 pl-[1px]  h-6 flex justify-center items-center text-primary rounded-full bg-white border-primary`}
        >
          <BiChevronLeft size={23} />
        </div> */}

        <div className="flex flex-col gap-x-4  ">
          <div
            className={`  overflow-hidden relative duration-300 rounded-md p-[2px] mx-auto ${
              isOpen && "scale-150"
            }`}
          >
            {/* <img src={logo} /> */}
            <h1 className="text-lightBlack text-2xl font-bold ">Phineas</h1>
          </div>

          <ul className="pt-6 space-y-1">
            <li>
              <Link
                to="/"
                className={`flex gap-x-2 p-2 items-center ${
                  pathname === "/" && "bg-light hover:bg-light"
                } hover:bg-light rounded-md`}
              >
                <PiSquaresFourFill
                  size={24}
                  className={`${
                    pathname === "/" ? "text-primary" : "text-gray-400"
                  }`}
                />
                <div
                  className={`${!isOpen && "hidden"}  ${
                    pathname === "/" ? "text-primary" : "text-gray-400"
                  } duration-300  text-sm font-semibold`}
                >
                  Dashboard
                </div>
              </Link>
            </li>

            <li>
              <Link
                to="/history"
                className={`flex gap-x-2 p-2 items-center ${
                  pathname === "/history" && "bg-light hover:bg-light"
                } hover:bg-light rounded-md`}
              >
                <GoClock
                  size={24}
                  className={`${
                    pathname === "/history" ? "text-primary" : "text-gray-400"
                  }`}
                />
                <div
                  className={`${!isOpen && "hidden"}  ${
                    pathname === "/history" ? "text-primary" : "text-gray-400"
                  } duration-300  text-sm font-semibold`}
                >
                  History
                </div>
              </Link>
            </li>

            <li>
              <Link
                to="/saved"
                className={`flex gap-x-2 p-2 items-center ${
                  pathname === "/saved" && "bg-light hover:bg-light"
                } hover:bg-light rounded-md`}
              >
                <BsBookmarkDash
                  size={24}
                  className={`${
                    pathname === "/saved" ? "text-primary" : "text-gray-400"
                  }`}
                />
                <div
                  className={`${!isOpen && "hidden"}  ${
                    pathname === "/saved" ? "text-primary" : "text-gray-400"
                  } duration-300  text-sm font-semibold`}
                >
                  Saved
                </div>
              </Link>
            </li>

            {/* /////Admin */}
            {user?.admin === true && (
              <li>
                <Link
                  to="/training"
                  className={`flex gap-x-2 p-2 items-center ${
                    pathname === "/training" && "bg-light hover:bg-light"
                  } hover:bg-light rounded-md`}
                >
                  <HiOutlineUpload
                    size={24}
                    className={`${
                      pathname === "/training"
                        ? "text-primary"
                        : "text-gray-400"
                    }`}
                  />
                  <div
                    className={`${!isOpen && "hidden"}  ${
                      pathname === "/training"
                        ? "text-primary"
                        : "text-gray-400"
                    } duration-300  text-sm font-semibold`}
                  >
                    Upload Trainings
                  </div>
                </Link>
              </li>
            )}
            {user?.admin === true && (
              <li>
                <Link
                  to="/admin"
                  className={`flex gap-x-2 p-2 items-center ${
                    pathname === "/admin" && "bg-light hover:bg-light"
                  } hover:bg-light rounded-md`}
                >
                  <PiSquaresFourFill
                    size={24}
                    className={`${
                      pathname === "/admin" ? "text-primary" : "text-gray-400"
                    }`}
                  />
                  <div
                    className={`${!isOpen && "hidden"}  ${
                      pathname === "/admin" ? "text-primary" : "text-gray-400"
                    } duration-300  text-sm font-semibold`}
                  >
                    Admin Page
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="h-screen bg-light w-screen overflow-scroll">
        <Navbar />
        {children}
      </div>
    </div>
  );
}

export default Sidebar;
