import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import { AppNavigation } from './navigation/appNavigation'
import Login from './screens/app/auth/login'
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from './firebase'
import { doc, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import useUser from './hooks/useUser';
import useHistory from './hooks/useHistory';
import { Toaster } from 'react-hot-toast';
import OriginalTextModal from './components/modals/originalTextModal';
import { Oval } from "react-loader-spinner";

function App() {



  const [userId, setUserId] = useState(null)

  const { user, setUser } = useUser()
  const { setHistory } = useHistory()



  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        console.log(uid)
        setUserId(uid)
        console.log('in state changed')
        // ...
      } else {
        console.log('logged out')
        setUser(null)
        setUserId(null)
        // User is signed out
        // ...
      }
    });


  }, [])


  useEffect(() => {
    if (userId) {
      const unsub = onSnapshot(doc(db, "users", userId), (doc) => {
        console.log("Current data: ", doc.data());
        setUser(doc.data())

      });
      return unsub
    }


  }, [userId])


  useEffect(() => {
    if (userId) {
      const q = query(collection(db, "history"), orderBy("timeStamp", 'desc'), where("uid", "==", userId));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const history = [];
        querySnapshot.forEach((doc) => {

          history.push({ id: doc.id, ...doc.data() });
        });
        console.log(history.length)
        setHistory(history)

      });
      return unsubscribe
    }
  }, [userId])


  const LoadingComp = () => <div className="flex w-full h-screen items-center justify-center bg-lightBlack">
    <Oval
      height={55}
      width={55}
      color="#fff"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#fff"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>


  return (
    user === 'loading' ? <LoadingComp /> :
      user ? <Sidebar >
        <Toaster />
        <AppNavigation />
        <OriginalTextModal />
      </Sidebar> : <>
        <Toaster />
        <Login />
      </>


  )
}

export default App