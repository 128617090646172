import React, { useState } from "react";
import useTab from "../hooks/useTab";
import useUser from "../hooks/useUser";
import { FiChevronDown } from "react-icons/fi";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useLocation } from "react-router-dom";

function Navbar() {
  const { tab, setTab } = useTab();
  const { user } = useUser();
  const [openMenu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();

  const logout = () => {
    setOpenMenu(false);
    signOut(auth);
  };

  console.log("user", user.photoUrl);
  const Tab = ({ title, index }) => {
    return (
      <div
        onClick={() => setTab(index)}
        className={`cursor-pointer p-6 border-b-2 ${
          index === tab
            ? "border-b-primary text-primary"
            : "text-gray-400 border-b-white"
        }   font-semibold text-lg`}
      >
        {title}
      </div>
    );
  };

  return (
    <div className="w-full flex px-2 justify-between bg-white border-l-[1px] border-gray-300 items-center">
      <div className="flex gap-4">
        {/* {pathname === "/" ? (
          <>
            <Tab title="Text" index={1} />
            <Tab title="Markup" index={2} />
            <Tab title="Rewrite" index={3} />
          </>
        ) : ( */}
        <div className="h-20"></div>
        {/* )} */}
      </div>
      <div className="flex gap-2 items-center p-1 border-[0.5px] border-gray-500 rounded-full relative">
        <img
          src={user.photoUrl}
          alt="profile"
          className="w-6 h-6 rounded-full object-cover"
        />
        <h1 className="text-sm text-gray-400 font-light">{user?.email}</h1>

        <FiChevronDown
          className="text-gray-700 cursor-pointer"
          onClick={() => setOpenMenu(!openMenu)}
        />

        {openMenu && (
          <div className="absolute p-4 bg-light w-48 rounded-xl shadow-lg right-2 top-6">
            <h1
              onClick={() => logout()}
              className="cursor-pointer text-primary font-semibold"
            >
              Logout
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
