import React, { useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import Navbar from "../../components/Navbar";
import Btn from "../../components/btn";
import { LuCopy } from "react-icons/lu";
import { CiBookmarkMinus } from "react-icons/ci";
import { RxDownload } from "react-icons/rx";
import { FiShare2 } from "react-icons/fi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { collection, addDoc, setDoc, Timestamp, doc } from "firebase/firestore";
import { db } from "../../firebase";
import useUser from "../../hooks/useUser";
import History from "./history";
import Saved from "./saved";
import toast from "react-hot-toast";
import { RWebShare } from "react-web-share";
import { jsPDF } from "jspdf";
import axios from "axios";

function Home() {
  const fileInputRef = useRef();
  const textareaRef = useRef();
  const [simpleText, setSimpleText] = useState("");
  const [reWritten, setReWritten] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [type, setType] = useState("CEFR - A1");
  const types = [
    "CEFR - A1",
    "CEFR - A2",
    "CEFR - B1",
    "CEFR - B2",
    "CEFR - C1",
    // "CEFR - C2",
  ];
  const { user, setUser } = useUser();
  const [currentIdToSave, setcurrentIdToSave] = useState("");
  const [reWriteText, setRewriteText] = useState("Rewrite");

  const handleChange = (event) => {
    // do something with event data

    const file = event?.target?.files[0];

    if (file) {
      console.log(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        console.log(text);
        autoGrow(text);
      };
      reader.readAsText(file);
    } else {
      console.log("No fILES");
    }
  };

  function autoGrow(text) {
    setSimpleText(text);
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  const rewriteText = async (text) => {
    if (!text) {
      alert("Text must not be empty!");
      return;
    }
    setRewriteText("Rewriting...")
    try {
      // const textTemp = `Response Will be shown after next milestone`;
      const res = await axios.post(`${process.env.REACT_APP_AI_URL}/rewrite`, {
        data: {
          text: text,
          threshold: type.split(" ")[2],
        },
      });

      // console.log("res.data", res.data)
      setReWritten(res.data);
      setRewriteText("Rewrite")

      const docRef = await addDoc(collection(db, "history"), {
        action: "Rewrite",
        type: type,
        originalText: text,
        newText: res.data,
        timeStamp: Timestamp.fromDate(new Date()),
        saved: false,
        uid: user?.uid,
      });
      //   console.log("--->", docRef.id);
      setcurrentIdToSave(docRef.id);
      toast.success("Success");
    } catch (error) {
      setRewriteText("Rewrite")
      toast.error("Something went wrong!")
      console.log(error);
    }
  };

  const saveText = async () => {
    if (!currentIdToSave) {
      toast.error("Nothing to save");
      return;
    }
    console.log("current id to save", currentIdToSave);

    try {
      const docRef = await setDoc(
        doc(db, "history", currentIdToSave),
        { saved: true },
        { merge: true }
      );
      toast.success("Saved");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const copyText = async () => {
    window.navigator.clipboard.writeText(reWritten);
    toast.success("Copied");
  };

  const downloadPdf = () => {
    const doc = new jsPDF({
      orientation: "portrait", // or 'landscape'
      unit: "mm",
      format: "a4", // or 'letter' or other formats
    });

    let y = 20;

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    // doc.text("Rephrased", 10, y);
    y += 10;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    const lines = reWritten.split("\n");

    lines.forEach((line) => {
      // Check for automatic line breaks
      const options = { autoBreak: true, maxWidth: 190 };

      // Split long lines into multiple lines
      const splitLines = doc.splitTextToSize(line, options.maxWidth, options);

      // Iterate through the split lines
      splitLines.forEach((splitLine) => {
        doc.text(10, y, splitLine);
        y += 10; // Increment y for the next line
      });
    });

    doc.save("new.pdf");
  };

  const Rewrite = () => {
    const Action = ({ icon, onClick }) => {
      return (
        <div
          onClick={onClick}
          className="cursor-pointer w-10 h-10 bg-white rounded-xl flex items-center justify-center"
        >
          {icon}
        </div>
      );
    };

    return (
      <div className="w-full min-h-[200px]  overflow-scroll bg-greyLight rounded-lg flex flex-col p-2">
        <div className="flex flex-row items-center justify-end gap-4">
          {/* <Btn
            title={"Markup"}
            onClick={() => {
              // rewriteText(reWritten)
            }}
          /> */}
        </div>

        {/* <div className="h-[1px] w-full bg-gray-300 mt-4" /> */}
        <p className="mb-4">{reWritten}</p>

        <div className="flex items-center justify-end gap-2 mt-auto">
          <Action onClick={copyText} icon={<LuCopy className="text-lg" />} />
          <Action
            onClick={saveText}
            icon={<CiBookmarkMinus className="text-lg" />}
          />
          <Action
            onClick={downloadPdf}
            icon={<RxDownload className="text-lg" />}
          />
          <RWebShare
            data={{
              text: reWritten,

              title: "Rewritten ",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <Action icon={<FiShare2 className="text-lg" />} />
          </RWebShare>
          {/* <Action icon={<AiOutlineInfoCircle className="text-lg" />} /> */}
        </div>
      </div>
    );
  };

  return (
    <div onClick={() => setOpenMenu(false)} className="flex flex-col w-full">
      <div className="flex items-center justify-between w-[100%] md:w-[50%] m-2">
        <h1 className="font-semibold">Input or Upload Text</h1>
        <div
          onClick={(e) => {
            e.stopPropagation();
            fileInputRef.current.click();
          }}
          className="cursor-pointer border-[1px] p-2 px-6 flex items-center justify-center font-light text-secondary border-secondary rounded-full"
        >
          UPLOAD .TXT
          <input
            onChange={handleChange}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
            accept=".txt"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 m-2">
        <div className="w-full min-h-[200px]  bg-white rounded-lg flex flex-col p-2">
          <div className="flex flex-row items-center gap-4">
            <Btn
              title={reWriteText}
              onClick={(e) => {
                e.stopPropagation();
                rewriteText(simpleText);
              }}
            />
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpenMenu(!openMenu);
              }}
              className="cursor-pointer p-2 px-4 gap-2 text-secondary flex items-center justify-center relative"
            >
              <h1>{type}</h1>
              <FiChevronDown className=" cursor-pointer text-xl" />
              {openMenu && (
                <div className="absolute p-4 bg-light w-48 rounded-xl shadow-lg right-2 top-6">
                  {types.map((t) => (
                    <h1
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenMenu(false);
                        setType(t);
                      }}
                      className="cursor-pointer text-primary my-2"
                    >
                      {t}
                    </h1>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="h-[1px] w-full bg-gray-300 mt-4" />
          <textarea
            placeholder="Enter your text here ..."
            ref={textareaRef}
            value={simpleText}
            onChange={(e) => autoGrow(e?.target?.value)}
            className="p-2 w-full outline-none mt-2 h-10 rounded-md  min-h-[200px]  overflow-y-auto"
          />
        </div>

        <Rewrite />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
        <div>
          <History />
        </div>
        <div>
          <Saved />
        </div>
      </div>
    </div>
  );
}

export default Home;
