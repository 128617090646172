import React from "react";
import useHistory from "../../hooks/useHistory";
import Btn from "../../components/btn";
import moment from "moment";
import { BsBookmarkDashFill } from "react-icons/bs";
import toast from "react-hot-toast";
import { collection, addDoc, setDoc, Timestamp, doc } from "firebase/firestore";
import { db } from "../../firebase";
import useOriginalTextModal from "../../hooks/useOriginalTextModal";

function Saved() {
  const { history } = useHistory();
  const { onOpen, setText } = useOriginalTextModal();

  const unsave = async (currentIdToSave) => {
    if (!currentIdToSave) {
      toast.error("Nothing to save");
      return;
    }
    console.log("current id to save", currentIdToSave);

    try {
      const docRef = await setDoc(
        doc(db, "history", currentIdToSave),
        { saved: false },
        { merge: true }
      );
      toast.success("Saved");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const HistoryComp = ({ item }) => {
    console.log(item);
    return (
      <div className="border-t-[0.5px] border-t-gray-400 p-2 py-4 flex flex-col">
        <div className="flex w-full items-center justify-between">
          {/* <Btn title={item?.action} /> */}
          <div className="cursor-pointer p-2 px-4 bg-light rounded-xl text-primary flex items-center justify-center">
            {item?.type}
          </div>
          <div className="flex items-center gap-3">
            {/* <div className="text-sm text-gray-400">
              {moment(new Date(item?.timeStamp?.toDate())).format(
                "MMM Do | h:mm a"
              )}
            </div> */}

            <div
              onClick={() => {
                setText(item?.originalText);
                onOpen();
              }}
              className="cursor-pointer border-[1px] p-1 px-4 flex items-center justify-center font-light text-secondary border-secondary rounded-full"
            >
              Original Text
            </div>

            <BsBookmarkDashFill
              onClick={() => unsave(item?.id)}
              className="text-primary text-xl cursor-pointer"
            />
          </div>
        </div>

        <div className="p-2">{item?.newText}</div>
      </div>
    );
  };

  return (
    <>
      <div className=" flex flex-col bg-white rounded-xl m-4">
        <h1 className="font-semibold m-4">Saved</h1>

        {history
          .filter((e) => e.saved === true)
          .map((item) => (
            <HistoryComp item={item} />
          ))}
      </div>
      <div className="h-10" />
    </>
  );
}

export default Saved;
