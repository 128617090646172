import React from "react";

function Btn({ title, onClick }) {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer p-2 px-4 bg-secondary rounded-xl text-white flex items-center justify-center"
    >
      {title}
    </div>
  );
}

export default Btn;
