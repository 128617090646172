// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref as storageRef } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAGGcQU4aKjj6uFeMPaGTnbsQdE9yRqehI",
    authDomain: "phineas-4f1f7.firebaseapp.com",
    projectId: "phineas-4f1f7",
    storageBucket: "phineas-4f1f7.appspot.com",
    messagingSenderId: "371419723841",
    appId: "1:371419723841:web:afe2f4112039288ee4aec0",
    measurementId: "G-8L4GJ4JE04"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, storageRef, }